<template>
  <div class="contentView">
    <div class="centerView centerBox">
      <div>
        <a-select v-model:value="bId" style="width: 200px" :placeholder="t('selectShop')" allowClear size="large">
          <a-select-option :value="item.idCode" v-for="(item, index) in storeList" :key="index">{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div class="cardBox">
        <div class="tit">{{ t('account') }}</div>
        <div class="num">{{ money }}</div>
      </div>
    </div>

    <!-- <div class="centerView">
      <a-card style="width: 300px">
        <a-statistic
          title="账户可用余额（美元）"
          :value="money"
          :value-style="{ color: money > 0 ? '#3f8600' : '#f81d22' }"
          style="margin-right: 50px"
        >
        </a-statistic>
      </a-card>
    </div> -->

    <div class="centerView">
      <a-table v-if="!loadRef" :dataSource="list" :columns="columns" :pagination="false" :row-key="r => r.id">
        <template #createAt="{ text }">
          {{ text.createAt ? text.createAt.split('.')[0] : '--' }}
        </template>

        <template #inAmount="{ text }">
          <!-- 1:充值、2:销售佣金、3:坑位费、4:期末余额、5:店播套餐、6:店播佣金、7:特殊场次、8:小视频、9:其他费用、10:退款、11：抵冲 -->
          <div v-if="text.type == 1 || text.type == 4 || text.type == 11">
            {{ text.amountUSD }}
          </div>
          <div v-else>--</div>
        </template>
        <template #period="{ text }">
          {{ text.period ? text.period : '--' }}
        </template>
        <template #outAmount="{ text }">
          <div v-if="text.type == 2 || text.type == 3">
            {{ text.amountUSD }}
          </div>
          <div v-else>--</div>
        </template>

        <template #subtotalUSD="{ text }">
          <div v-if="text.subtotalUSD >= 0">
            {{ text.subtotalUSD }}
          </div>
          <div v-else style="color: red">{{ text.subtotalUSD }}</div>
        </template>
      </a-table>
      <div style="width: 100%" v-else>
        <a-space :loading="false" :active="true">
          <template v-for="item in 8" :key="item">
            <a-skeleton-button :active="true" style="width: 100%; margin-bottom: 8px; height: 70px"> </a-skeleton-button>
          </template>
        </a-space>
      </div>
      <div class="pagination_cont">
        <a-pagination v-model:current="page.page" :total="page.total" @change="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import { balance, balance_page } from '../../../api/financial'
  import { getStoreList } from '../../../api/live'
  import { ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'settlement',
    setup() {
      const loadRef = ref(true)
      const { t, locale } = useI18n()
      const bId = ref(null)
      const storeList = ref([])
      const columns = ref()
      function getStoreListFn() {
        getStoreList().then(res => {
          if (res.code === 200) {
            storeList.value = res.data
            balanceFn()
            balance_pageFn()
          }
        })
      }

      watch(
        () => bId.value,
        () => {
          page.value.page = 1
          balanceFn()
          balance_pageFn()
        }
      )

      getStoreListFn()

      const money = ref(0)

      const balanceFn = () => {
        balance({
          storesIdCode: bId.value ? bId.value : null,
        }).then(res => {
          console.log(res)
          money.value = res.data
          console.log(money.value)
        })
      }

      const page = ref({
        page: 1,
        size: 10,
        total: 0,
      })

      const list = ref([])

      const balance_pageFn = () => {
        loadRef.value = true
        balance_page({
          ...page.value,
          storesIdCode: bId.value ? bId.value : null,
        }).then(res => {
          setTimeout(() => {
            loadRef.value = false
          }, 1000)
          list.value = res.data.data
          page.value.total = res.data.total
        })
      }
      watch(locale, () => {
        columnFun()
      })
      const columnFun = () => {
        columns.value = [
          {
            title: t('chargeTable.enterPrice'),
            key: 'createAt',
            width: 150,
            slots: { customRender: 'createAt' },
          },
          {
            title: t('chargeTable.accountType'),
            dataIndex: 'typeVal',
            key: 'typeVal',
            width: 70,
          },
          {
            title: t('chargeTable.accountCycle'),
            key: 'period',
            slots: { customRender: 'period' },
            width: 150,
          },
          {
            title: t('chargeTable.remitPrice'),
            dataIndex: 'rechargeAmountUSD',
            key: 'rechargeAmountUSD',
            width: 70,
          },
          {
            title: t('chargeTable.entryPrice'),
            width: 70,
            slots: { customRender: 'inAmount' },
          },
          {
            title: t('chargeTable.expendPrice'),
            slots: { customRender: 'outAmount' },
            width: 70,
          },
          {
            title: t('chargeTable.subtotalPrice'),
            key: 'subtotalUSD',
            width: 70,
            slots: { customRender: 'subtotalUSD' },
          },
        ]
      }
      columnFun()
      const pageChange = val => {
        page.value.page = val
        balance_pageFn()
      }

      return {
        loadRef,
        money,
        list,
        page,
        pageChange,
        columns,
        bId,
        storeList,
        t,
      }
    },
  }
</script>

<style scoped lang="less">
  ::v-deep(.ant-space-align-center) {
    width: 100%;
  }
  ::v-deep(.ant-space-item) {
    width: 100%;
  }
  ::v-deep(.ant-space) {
    display: flex;
    flex-direction: column;
  }
  ::v-deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: 10px;
  }
  .centerBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .cardBox {
      background: #fafafa;
      border: 1px solid #f7f7f7;
      border-radius: 8px;
      width: 170px;
      height: 76px;
      padding: 12px 8px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .tit {
        font-size: 14px;
        line-height: 20px;
        color: #7a7576;
      }
      .num {
        color: #ff6700;
        font-size: 24px;
        line-height: 30px;
        margin-top: 6px;
      }
    }
  }
</style>
